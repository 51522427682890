<form [formGroup]="configurationForm" *ngIf="configurationForm" (submit)="onclickSaveConfiguration()">
  <h3 id="configurationLabel">{{configurationLabelText}}</h3>
  <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
  <error-display [errorModel]="errorModel"></error-display>

  <div style="margin-bottom: 10px;">
      <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
        <li [ngbNavItem]="1" [disabled]="showSpinner">
          <a ngbNavLink (click)="onclickGoToTab(1)">Setup</a>
          <ng-template ngbNavContent>
            <div class="ml5">
              <div class="row">
                <div class="col-12">
                    <!-- <form> -->
                      <div style="margin-bottom: 10px;">
                        <span class="text-muted"><small>Basic setup expects message to be sent to the Global Order Hub HTTP endpoint. For other protocals like Service Bus, MQ or File Share, you need to configure that source. You could also have a need for setting up AS2 or transforming the message from one format to an other. Below you specify all extra configurations.</small></span>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="specifyEnrichment" value="" name="specifyEnrichment" id="specifyEnrichment" (change)="configurationTabService.onSpecifyEnrichment($event, tabStatuses, tabModel)">
                        <label class="form-check-label" for="specifyEnrichment">Setup Enrichment</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="specifyAS2Outbound" value="" name="specifyAS2Outbound" id="specifyAS2Outbound" (change)="configurationTabService.onSpecifyAs2Outbound($event, tabStatuses, tabModel, configurationForm)">
                        <label class="form-check-label" for="specifyAS2Outbound">
                          Setup EDI Outbound
                        </label>
                      </div>
                    <!-- </form> -->
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-10">
                  <button type="button" class="btn btn-outline-secondary btn-sm" [disabled]="showSpinner" (click)="onclickGoToTab(2)">Next : Basic information</button>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" [disabled]="showSpinner">
          <a ngbNavLink (click)="onclickGoToTab(2)">Basic information</a>
          <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                      <inbound-partner-input formGroupName="inboundPartner" [partners]="basedataModel.partners" (partnerUpdated)="onPartnerUpdated()"></inbound-partner-input>
                      <messagetype-input formGroupName="inboundMessageType" [messageTypes]="basedataModel.inboundMessageTypes" (messageTypeUpdated)="onMessageTypeUpdated()"></messagetype-input>
                      <entitymap-input formGroupName="entitymap" (entityMapUpdated)="onEntityMapUpdated($event)"></entitymap-input>
                      <outbound-partner-input formGroupName="outboundPartner" [partners]="basedataModel.partners" (partnerUpdated)="onOutboundPartnerUpdated($event)"></outbound-partner-input>
                      <outbound-messagetype-input formGroupName="outboundMessageType" [outboundMessageTypes]="basedataModel.outboundMessageTypes"></outbound-messagetype-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-10">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(1)">Prev : Setup</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(tabModel.basicInfoNextTab)">{{tabModel.basicInfoNextText}}</button>
                  </div>
                </div>
              </div>
          </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="tabStatuses.specifySource" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(3)">Source</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <source-view [partnerId]="configurationForm.value.inboundPartner.partnerId"
                      [schedulers]="schedulers" [keyVaultProfiles]="basedataModel.keyVaultProfiles" [existingSchedulers]="basedataModel.schedulers"></source-view>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(2)">Prev: Basic</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(tabModel.sourceNextTab)">{{tabModel.sourceNextText}}</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4" *ngIf="configurationForm.value.specifyAS2Inbound" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(4)">AS2 Inbound</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="(configurationForm.value.inboundPartner.inboundPartnerId === '')">
                      <b>Partner need to be selected in order to configure AS2 inbound. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <as2-inbound-input formGroupName="as2ConfIn"></as2-inbound-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(tabModel.as2InboundPrevTab)">{{tabModel.as2InboundPrevText}}</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(5)">Next : Resolver/Parser</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(5)">Resolver/Parser</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <div>
                    </div>
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="(configurationForm.value.inboundPartner.inboundPartnerId === '') || (configurationForm.value.inboundMessageType.inboundMessageTypeId === '')">
                      <b>Partner and Message Type need to be selected in order to configure Pre-formatting, Resolver, and Parser. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <pre-step-info *ngIf="preStep" [preStep]="preStep"></pre-step-info>
                    <pre-formatting-input formGroupName="preFormatting" [preFormatters]="basedataModel.preFormattingClasses"></pre-formatting-input>
                    <resolver-info formGroupName="resolverClass"></resolver-info>
                    <parser-info formGroupName="parserClass"></parser-info>
                    <response-resolver-input [responseResolver]="responseResolverUpdateData" [responseResolvers]="basedataModel.responseResolverClasses"></response-resolver-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(tabModel.resolverPrevTab)">{{tabModel.resolverPrevText}}</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(tabModel.resolverNextTab)"> {{tabModel.resolverNextText}}</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="6" *ngIf="tabStatuses.specifyEnrichment" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(6)">Enrichments</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="form.entitymap.value.inboundMessageTypeId === -1 || form.entitymap.value.senderId === '' || form.entitymap.value.receiverId === ''">
                      <b>Basic information need to be entered in order to configure Enrichments. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <enrichment [enrichments]="enrichments" [enrichmentClasses]="enrichmentClasses" (enrichmentUpdated)="onEnrichmentUpdated($event)"></enrichment>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(5)">Prev : Resolver/Parser</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(7)">Next : Mapping</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="7" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(7)">Mapping</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="form.inboundPartner.value.inboundPartnerId === '' || form.outboundPartner.value.outboundPartnerId === '' || form.inboundMessageType.value.inboundMessageTypeId === '' || form.entitymap.value.receiverId === ''">
                      <b>Basic information need to be entered in order to configure Mappings. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <inbound-mapping-input [inboundPartnerId]="form.inboundPartner.value.inboundPartnerId" [inboundMessageTypeId]="form.inboundMessageType.value.inboundMessageTypeId" [canonicalMaps]="this.basedataModel.canonicalMaps"></inbound-mapping-input>
                    <!-- [entityMap]="entityMap" No need to send in entityMap to outbound-mapping-input as it's not used -->
                    <outbound-mapping-input formGroupName="outboundMapXslt" [outboundMaps]="outboundMaps"></outbound-mapping-input>
                    <post-formatting-input formGroupName="postFormatting" [postFormatters]="basedataModel.postFormattingClasses"></post-formatting-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(tabModel.mappingPrevTab)">{{tabModel.mappingPrevText}}</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(8)">Destinations</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="8" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(8)">Destinations</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="(form.outboundPartner.value.outboundPartnerId === '') || form.entitymap.value.legalEntityId === ''">
                      <b>Basic information and Outbound Partner is required in order to configure destinations. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <destination-input (destinationUpdated)="onDestinationUpdated($event)" [confDestConnections]="confDestConnections" [outboundPartnerId]="form.outboundPartner.value.outboundPartnerId" [entityMapId]="form.entitymap.value.id" [keyVaultProfiles]="basedataModel.keyVaultProfiles" [confDestinations]="basedataModel.confDestinations"></destination-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(7)">Prev : Mapping</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(tabModel.destinationsNextTab)">{{tabModel.destinationsNextText}}</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="9" *ngIf="tabStatuses.specifyAS2Outbound" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(9)">EDI outbound</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="row">
                  <div class="col-12">
                    <!-- TODO check whole basic info data for ngIf??? -->
                    <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="(form.outboundPartner.value.outboundPartnerId === '') || form.entitymap.value.legalEntityId === ''">
                      <b>Basic information need to be entered in order to configure Mappings. Please go to Basic information tab and enter the missing information.</b>
                    </div>
                    <as2-outbound-input formGroupName="outboundEdi"></as2-outbound-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(8)">Prev : Destination</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(10)">Review + create</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="10" [disabled]="showSpinner">
            <a ngbNavLink (click)="onclickGoToTab(10)">Review</a>
            <ng-template ngbNavContent>
              <div class="ml5">
                <div class="alert alert-danger" style="margin-top: 10px;" role="alert" *ngIf="!configurationForm.valid">
                  <b>You are missing input for required data.</b>
                </div>
                <div class="row">
                  <div class="col-12">
                    <!-- TODO Merge all to a class for updateData? -->
                    <review-input [tabStatuses]="tabStatuses"
                      [schedulers]="schedulers" [confDestConnections]="confDestConnections" [enrichments]="enrichments"
                      [responseResolver]="responseResolverUpdateData"></review-input>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-secondary btn-sm" style="margin-right: 5px;" (click)="onclickGoToTab(tabModel.reviewPrevTab)">{{tabModel.reviewPrevText}}</button>
                    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!configurationForm.valid">Finish</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>

  <!-- <div *ngIf="configurationForm">
    configurationForm: {{configurationForm.valid}}
  </div> -->
  <!-- <div *ngIf="configurationForm">
<pre style="margin-top:10px; border: 1px solid red; width: fit-content">
{{ configurationForm.value | json }}
</pre>
  </div>  -->
</form>


