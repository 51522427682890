import { KeyVaultProfile } from "../key-vault-profile";

export class DestinationHttpprofile {
    id: number;
    name: string;
    host: string;
    port: string;
    path: string;
    userName: string;
    password: string;
    clientId: string;
    tokenUrl: string;
    scope: string;
    authentication: number;
    keyVaultProfileId: number;
    keyVaultProfile: KeyVaultProfile;
    createdBy: string;
    createTimestamp: string;
    systemName: string;
}