import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { UtilService } from 'src/app/common-services/util.service';
import { Partner } from 'src/app/models/partner/partner';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { InboundAS2ConfigurationService } from './inbound-as2-configuration.service';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'inbound-as2-configuration',
  templateUrl: './inbound-as2-configuration.componet.html',
})
export class InboundAs2ConfigurationComponent implements OnInit {
    @Input() partner: Partner;
    public showSpinner: boolean = false;
    public errorModel: ErrorModel = null;
    public isAdmin: boolean = false;
    public as2ConfinForm: FormGroup;

    constructor(private authService: MsalService, public utilService: UtilService, private errorParserService: ErrorParserService, public as2Service: InboundAS2ConfigurationService) {}

    ngOnInit(): void {
        this.as2ConfinForm = this.as2Service.getEmptyAs2ConfinForm();

        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
        }
        
        this.getAS2ConfIn(this.partner.id);
    }

    get form() { return this.as2ConfinForm.controls; }

    public getAS2ConfIn(partnerId: number) {
      this.as2Service.getAs2Confin(partnerId).subscribe({
          next: (as2Confin) => {
              this.showSpinner = false;

              if(as2Confin != undefined) {
                this.as2ConfinForm = this.as2Service.getAs2ConfinForm(as2Confin)
              }
          },
          error: (error) => {
              this.showSpinner = false;
              this.errorModel = this.errorParserService.extractErrors(error);
          }
      });
    }
    
    public save() {
        this.showSpinner = true; 

        let as2ConfinModel = this.as2Service.getAs2ConfinModel(this.as2ConfinForm);
        as2ConfinModel.inboundPartnerId = this.partner.id;
        
        if(this.as2ConfinForm.value.useAs2Confin === true) {
            if(this.as2ConfinForm.value.id === 0) {
                this.as2Service.addAs2Confin(as2ConfinModel).subscribe({
                    next: (as2Confin) => {
                        this.showSpinner = false;
          
                        this.getAS2ConfIn(this.partner.id);
                    },
                    error: (error) => {
                        this.showSpinner = false;
                        this.errorModel = this.errorParserService.extractErrors(error);
                    }
                });
            } else {
                // Update
                this.as2Service.updateAs2Confin(as2ConfinModel).subscribe({
                    next: (as2Confin) => {
                        this.showSpinner = false;
          
                        this.getAS2ConfIn(this.partner.id);
                    },
                    error: (error) => {
                        this.showSpinner = false;
                        this.errorModel = this.errorParserService.extractErrors(error);
                    }
                });
            }
        } else {
            if(this.as2ConfinForm.value.id > 0) {
                // Delete
                this.as2Service.deleteAs2Confin(as2ConfinModel).subscribe({
                    next: (as2Confin) => {
                        this.showSpinner = false;
          
                        this.getAS2ConfIn(this.partner.id);
                    },
                    error: (error) => {
                        this.showSpinner = false;
                        this.errorModel = this.errorParserService.extractErrors(error);
                    }
                });
            } 
        }

        // let prestepClass = this.service.getPrestepClassModel(this.prestepClassForm);

        // this.service.addPrestepClass(prestepClass).subscribe(
        //     {
        //         next: (result) => {
        //             this.showSpinner = false; 
        //             this.activeModal.close('save');
        //         },
        //         error: (error) => {
        //             this.showSpinner = false; 
        //             this.errorModel = this.errorParserService.extractErrors(error);
        //             console.error(error);
        //         }
        //      }
        // );
    }

    selectUseAs2ConinChangeHandler(event: any) {
        // if(this.inboundPartnerSourceForm.value.isSimplified === true)
        // {
        // this.sourceInputService.getAllInboundMessageTypes().subscribe(
        //     {
        //         next: (result) => {
        //         this.inboundMessageTypesDDL = new Array<DropDownListModel>();
        //         result.forEach(x => {
        //             let d: DropDownListModel = {
        //                 value: x.inboundMessageTypeId.toString(),
        //                 text: `${x.messageTypeDescription} (${x.messageName})`
        //             };
        //             if(x.inboundMessageTypeId != 0){
        //                 this.inboundMessageTypesDDL.push(d);
        //             }
                    
        //         });
        //         },
        //         error: (error) => {
        //             this.showSpinner = false;
        //             console.error(error);
        //         }
        //     }
        // );      
        // var temp = this.inboundPartnerSourceForm.value.inboundMessageTypeId;
        // this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:temp})
        // }
        // else{
        // this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:''})
        // }
        // this.sourceInputService.changeValidators(this.inboundPartnerSourceForm);
    }

    // public addParserConnection() {
    //     const modalRef = this.modalService.open(AddParserModalComponent, { size: 'size_40' });
    //     modalRef.componentInstance.partner = this.partner;
    //     modalRef.componentInstance.parsersGridList = this.parsersGridList;

    //     modalRef.result.then((result) => {
    //         // Refresh grid after save
    //         if(result === 'save') {
    //             this.getParsers(this.partner.id);
    //             //this.sourceUpdated.emit(this.schedulers);
    //             //this.getData(1, 10);
    //         }
    //     }, (reason) => {
    //         // User clicked outside modal window => do nothing (same as cancel)            
    //     });
    // }

    // public editParser(inboundParserResponseModal: InboundParserResponseModel) {
    //     const modalRef = this.modalService.open(EditParserModalComponent, { size: 'size_40' });
    //     modalRef.componentInstance.partner = this.partner;
    //     modalRef.componentInstance.parsersGridList = this.parsersGridList;
    //     let inboundParser: InboundParser = new InboundParser();
    //     inboundParser.id = inboundParserResponseModal.id;
    //     inboundParser.partner = this.partner.id;
    //     inboundParser.messageType = inboundParserResponseModal.messageType;
    //     inboundParser.parserClassId = inboundParserResponseModal.parserClassId;
    //     inboundParser.senderIdPath = inboundParserResponseModal.senderIdPath;
    //     inboundParser.receiverIdPath = inboundParserResponseModal.receiverIdPath;
    //     inboundParser.primaryIdPath = inboundParserResponseModal.primaryIdPath;
    //     inboundParser.referenceIdPath = inboundParserResponseModal.referenceIdPath;
    //     inboundParser.taskIdPath = inboundParserResponseModal.taskIdPath;
    //     inboundParser.countryCodePath = inboundParserResponseModal.countryCodePath;
    //     inboundParser.customLookupIdPath = inboundParserResponseModal.customLookupIdPath;
    //     inboundParser.areaPlantIdPath = inboundParserResponseModal.areaPlantIdPath;
    //     inboundParser.namespacesUri = inboundParserResponseModal.namespacesUri;

    //     modalRef.componentInstance.inboundParser = inboundParser;
        
    //     modalRef.result.then((result) => {
    //         // Refresh grid after save
    //         if(result === 'save') {
    //             this.getParsers(this.partner.id);
    //             //this.sourceUpdated.emit(this.schedulers);
    //             //this.getData(1, 10);
    //         }
    //     }, (reason) => {
    //         // User clicked outside modal window => do nothing (same as cancel)            
    //     });
    // }

  

    // public removeParserPartnerConnection(inboundParserResponseModal: InboundParserResponseModel) {
    //     let inboundParser: InboundParser = new InboundParser();
    //     inboundParser.id = inboundParserResponseModal.id;
    //     inboundParser.partner = this.partner.id;
    //     inboundParser.messageType = inboundParserResponseModal.messageType;
    //     inboundParser.parserClassId = inboundParserResponseModal.parserClassId;

    //     const modalRef = this.modalService.open(DeleteParserConnectionModalComponent, { size: 'size_40' });
    //     modalRef.componentInstance.inboundParser = inboundParser;
    //     modalRef.componentInstance.partner = this.partner;

    //     modalRef.result.then((result) => {
    //         // Refresh grid after save
    //         if(result === 'delete') {
    //             this.getParsers(this.partner.id);
    //         }
    //     }, (reason) => {
    //         // User clicked outside modal window => do nothing (same as cancel)            
    //     });
    // }

//     private displayErrorMessage(error: any) {
//       let formattedError = '';
//       if(error?.error && error?.error.error == undefined) {
//       if(error.error.includes('at al.goh.admin.tool.api')) {
//           formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
//       } else {
//           formattedError = error.error;
//       }
//       } else {
//       if(error?.error?.error) {
//           formattedError = error?.error.error;
//       } else {
//           formattedError = 'Unknown eror';
//       }
//       }

//       this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
//       this.error = true;
//       console.error(error);
//   }

}
