<form [formGroup]="as2ConfinForm" (submit)="save()">


    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div>
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound AS2 configuration</span></legend>

            <div class="form-group row mb5">
                <div class="col-sm-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="useAs2Confin" formControlName="useAs2Confin" value="" aria-label="..." (change)="selectUseAs2ConinChangeHandler($event)">
                        <label class="form-check-label" for="useAs2Confin">
                            Use inbound AS2 configuration
                        </label>
                    </div>
                </div>
            </div>

            <!-- Request format -->
            <div *ngIf="as2ConfinForm && as2ConfinForm.value.useAs2Confin === true">
                <div class="form-group row form-inline mb10">
                    <label for="requestFormat" class="col-sm-2 col-form-label control-label">Request format</label>
                    <div class="col-sm-4">
                        <select dropdown id="requestFormat" name="requestFormat" formControlName="requestFormat" class="form-select form-select-sm control-label" >
                            <option *ngFor="let item of as2Service.as2formats" [value]="item.value">
                                {{item.text}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- MDN format -->
            <div *ngIf="as2ConfinForm && as2ConfinForm.value.useAs2Confin === true">
                <div class="form-group row form-inline mb10">
                    <label for="mdnformat" class="col-sm-2 col-form-label control-label">MDN format</label>
                    <div class="col-sm-4">
                        <select dropdown id="mdnformat" name="mdnformat" formControlName="mdnformat" class="form-select form-select-sm control-label" >
                            <option *ngFor="let item of as2Service.as2formats" [value]="item.value">
                                {{item.text}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Ack format -->
            <div *ngIf="as2ConfinForm && as2ConfinForm.value.useAs2Confin === true">
                <div class="form-group row form-inline mb10">
                    <label for="ackFormat" class="col-sm-2 col-form-label control-label">Ack format</label>
                    <div class="col-sm-4">
                        <select dropdown id="ackFormat" name="ackFormat" formControlName="ackFormat" class="form-select form-select-sm control-label" >
                            <option *ngFor="let item of as2Service.as2formats" [value]="item.value">
                                {{item.text}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Acknowledge enabled -->
            <div *ngIf="as2ConfinForm && as2ConfinForm.value.useAs2Confin === true">
                <div class="form-group row mb5">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="ackEnabled" formControlName="ackEnabled" value="" aria-label="...">
                            <label class="form-check-label" for="ackEnabled">
                                Acknowledge enabled
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Ack endpoint -->
            <div *ngIf="as2ConfinForm && as2ConfinForm.value.useAs2Confin === true && as2ConfinForm.value.ackEnabled === true">
                <div class="form-group row mb5">
                    <label for="ackEndpoint" class="col-sm-2 col-form-label">Ack endpoint</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" formControlName="ackEndpoint" id="ackEndpoint" name="ackEndpoint" placeholder="Ack endpoint">
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-outline-dark" [disabled]="!as2ConfinForm.valid">Save</button>
        </fieldset>
    </div>
</form>