<form [formGroup]="destinationHttpprofileForm">
    <hr>
    <!-- Selection -->
   <div class="form-group row mb5" *ngIf="!isEdit">
       <div class="col-sm-3">        
       </div>
       <div class="col-sm-9">
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="true" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestOptions1">
               Use existing destination
               </label>
           </div>
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="false" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestOptions2">
                   Add new destination
               </label>
           </div>
       </div>
   </div>

    <!-- Use existing physical destination -->
    <div class="form-group row" *ngIf="destinationHttpprofileForm.value.useExistingDestination === true">
       <label for="confDestinationDDL" class="col-sm-3 col-form-label control-label">Destinations <span class="req-color">*</span></label>
       <div class="col-sm-9">
            <select dropdown formControlName="id" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select destination ---</option>
            <option *ngFor="let item of httpProfiles" [value]="item.id">
                {{item.name}} ({{item.host}})
            </option>
            </select>
            <div *ngIf="form.id.invalid && (form.id.dirty || form.id.touched)" class="alert alert-danger">
                <div *ngIf="form.id.errors?.['required']">Destination is required.</div>
            </div>
       </div>
   </div>
   
   <!-- New physical destination -->
   <div *ngIf="destinationHttpprofileForm.value.useExistingDestination == false">
        <!-- Host -->
       <div class="form-group row mb5">
           <label for="destinationHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
           <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="host" id="destinationHost" name="destinationHost" placeholder="Host">
                <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
                    <div *ngIf="form.host.errors?.['required']">Host is required.</div>
                </div>
           </div>
       </div>
       <!-- Port -->
       <div class="form-group row mb5">
           <label for="destinationPort" class="col-sm-3 col-form-label">Port</label>
           <div class="col-sm-9">
               <input type="text" class="form-control" formControlName="port" id="destinationPort" name="destinationPort" placeholder="Port">
           </div>
       </div>
       <!-- Transfer queue -->
       <div class="form-group row mb5">
           <label for="destinationPath" class="col-sm-3 col-form-label">Transfer queue</label>
           <div class="col-sm-9">
               <input type="text" class="form-control" formControlName="path" id="destinationPath" name="destinationPath" placeholder="Path">
           </div>
       </div>
   
       <fieldset class="border p-2">
           <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Authentication</span></legend>
           <div class="form-group row mb10">
               <label for="selectAuthentication" class="col-sm-3 col-form-label control-label">Authentication</label>
               <div class="col-sm-9">
                   <select dropdown id="selectAuthentication" formControlName="authentication" name="selectAuthentication" class="form-select form-select-sm control-label">
                       <option *ngFor="let item of authenticationOptions" [value]="item.value">
                           {{item.text}}
                       </option>
                   </select>
               </div>
           </div>
          
           <!-- Username -->
           <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 1">
               <label for="destinationUsername" class="col-sm-3 col-form-label">Username</label>
               <div class="col-sm-9">
                   <input type="text" formControlName="userName" class="form-control" id="destinationUsername" name="destinationUsername" placeholder="Username">
               </div>
           </div>          
           <!-- Shared bearer token -->
           <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 4">
               <label for="destinationSharedBearerToken" class="col-sm-3 col-form-label">Shared bearer token</label>
               <div class="col-sm-9">
                   <input type="text" formControlName="password" class="form-control" id="destinationSharedBearerToken" name="destinationSharedBearerToken" placeholder="Shared bearer token">
               </div>
           </div>
           <!-- Static authentication token -->
           <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 5">
               <label for="destinationSharedBearerToken" class="col-sm-3 col-form-label">Static authentication token</label>
               <div class="col-sm-9">
                   <input type="text" formControlName="password" class="form-control" id="staticAuthenticationToken" name="staticAuthenticationToken" placeholder="Static authentication token">
               </div>
           </div>
            <!-- ClientId -->
            <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 2">
                <label for="clientId" class="col-sm-3 col-form-label">Client Id</label>
                <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="clientId" id="clientId" name="clientId" placeholder="Client Id">
                </div>
            </div>
            <!-- TokenURL -->
            <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 2">
                <label for="tokenUrl" class="col-sm-3 col-form-label">Token URL</label>
                <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="tokenUrl" id="tokenUrl" name="tokenUrl" placeholder="Token URL">
                </div>
            </div>
            <!-- Scope -->
            <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication === 2">
                <label for="scope" class="col-sm-3 col-form-label">Scope</label>
                <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="scope" id="scope" name="scope" placeholder="Scope">
                </div>
            </div>
            <div *ngIf="destinationHttpprofileForm.value.authentication !== 0" style="margin-bottom: 10px;">
                <!-- Selection -->
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-9">
                        <div class="form-check">
                            <input class="form-check-input" formControlName="useExistingKeyVaultValue" (change)="handleKeyVaultOptionsChange($event)" type="radio" name="useExistingKeyVaultValue" [value]="true" id="useExistingKeyVaultValue">
                            <label class="form-check-label" for="keyVaultOptions1">
                                Use existing key vault secret
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" formControlName="useExistingKeyVaultValue" (change)="handleKeyVaultOptionsChange($event)" type="radio" name="useExistingKeyVaultValue" [value]="false" id="useExistingKeyVaultValue" checked>
                            <label class="form-check-label" for="keyVaultOptions2">
                                Add key vault secret
                            </label>
                        </div>
                    </div>
                </div>        
            </div>
            <!-- Password -->
            <div class="form-group row mb5" *ngIf="(+destinationHttpprofileForm.value.authentication === 1 || +destinationHttpprofileForm.value.authentication === 2) && destinationHttpprofileForm.value.useExistingKeyVaultValue === false">
            <label for="destinationPassword" *ngIf="+destinationHttpprofileForm.value.authentication === 1" class="col-sm-3 col-form-label">Password</label>
            <label for="destinationPassword" *ngIf="+destinationHttpprofileForm.value.authentication === 2" class="col-sm-3 col-form-label">Secret</label>
            <div class="col-sm-9">
                <input type="password" formControlName="password" class="form-control" id="destinationPassword" name="destinationPassword" placeholder="Password">
            </div>
            </div>
            <!-- System name -->
            <div class="form-group row mb5" *ngIf="+destinationHttpprofileForm.value.authentication !== 0 && destinationHttpprofileForm.value.useExistingKeyVaultValue === false">
                <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the Basic Authentication has the form of al-goh-http-(basicauth/shared-bearer-token/static-authentication-token)-out-{system name}. The value you provide in the System name input will be used to construct the secret name."></i></label>
                <div class="col-sm-9">
                   <input type="text" class="form-control" formControlName="systemName" id="systemName" name="systemName" placeholder="System name">
                </div>
            </div>
           <!-- TODO Add possibility to select key vault -->
           <div class="form-group row" *ngIf="keyVaultProfiles != null && keyVaultProfiles.length > 0 && +destinationHttpprofileForm.value.authentication !== 0 && destinationHttpprofileForm.value.useExistingKeyVaultValue === true">
               <label for="keyvaultsecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
               <div class="col-sm-9">
                   <select dropdown formControlName="keyVaultProfileId" id="keyvaultsecretDDL" name="keyvaultsecretDDL" class="form-select form-select-sm control-label">
                       <option value="-1">--- Select secret ---</option>
                       <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                           {{item.name}}
                       </option>
                   </select>
               </div>
           </div>
       </fieldset>
   </div>
   <!-- <div>
    <h3>Validators:</h3>
    <ul>
    <li *ngFor="let controlName of destinationHttpprofileForm.controls | keyvalue">
        <strong>{{ controlName.key }}</strong> Validators: 
        {{ getValidators(controlName.value) }}
    </li>
    </ul>
   </div> -->
</form>