import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { AS2ConfIn } from 'src/app/models/edi/as2-conf-In';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownListModel, DropDownListModelNumber } from 'src/app/models/dropdown-list-model';

@Injectable({
  providedIn: 'root',
})
export class InboundAS2ConfigurationService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }
   
    public getAs2Confin(inboundPartnerId: number): Observable<AS2ConfIn> {
        return this.http.get<AS2ConfIn>(this.baseUrlLocal + `as2confin/${inboundPartnerId}`);
    }
 
    public addAs2Confin(aS2ConfIn: AS2ConfIn) {
        return this.http.post(this.baseUrlLocal + 'as2confin', aS2ConfIn);
    }

    public updateAs2Confin(aS2ConfIn: AS2ConfIn) {
        return this.http.put(this.baseUrlLocal + 'as2confin', aS2ConfIn);
    }

    public deleteAs2Confin(aS2ConfIn: AS2ConfIn) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: aS2ConfIn
        };
        return this.http.delete(this.baseUrlLocal + 'as2confin', options);
    }

    public getAs2ConfinForm(as2ConfIn: AS2ConfIn) {
        return new FormGroup({
            id: new FormControl(as2ConfIn.id),
            useAs2Confin: new FormControl(true),
            requestFormat: new FormControl(as2ConfIn.requestFormat, Validators.required),
            mdnformat: new FormControl(as2ConfIn.mdnformat, Validators.required),
            ackFormat: new FormControl(as2ConfIn.ackFormat, Validators.required),
            ackEndpoint: new FormControl(as2ConfIn.ackEndpoint),
            ackEnabled: new FormControl(as2ConfIn.ackEnabled)
        });
    }

    public getEmptyAs2ConfinForm() {
        return new FormGroup({
            id: new FormControl(0),
            useAs2Confin: new FormControl(false),
            requestFormat: new FormControl(0, Validators.required),
            mdnformat: new FormControl(0, Validators.required),
            ackFormat: new FormControl(0, Validators.required),
            ackEndpoint: new FormControl(''),
            ackEnabled: new FormControl(false)
        });
    }

      public getAs2ConfinModel(formGroup: FormGroup) {
        let as2ConfIn: AS2ConfIn = new AS2ConfIn();
        as2ConfIn.id = formGroup.value.id;
        as2ConfIn.requestFormat = formGroup.value.requestFormat;
        as2ConfIn.mdnformat = formGroup.value.mdnformat;
        as2ConfIn.ackFormat = formGroup.value.ackFormat;
        as2ConfIn.ackEndpoint = formGroup.value.ackEndpoint;
        as2ConfIn.ackEnabled = formGroup.value.ackEnabled;

        return as2ConfIn;
      }

    public as2formats: Array<DropDownListModelNumber> = [    
        { value: 0, text: "None" },
        { value: 1, text: "Plain text" },
        { value: 2, text: "Base64" },
        { value: 3, text: "Binary" },
    ];
}
