import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { Observable } from 'rxjs';
import { DestinationSbprofile } from 'src/app/models/destination/destination-sb-profile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationHttpprofile } from 'src/app/models/destination/destination-http-profile';
import { DestinationMqprofile } from 'src/app/models/destination/destination-mq-profile';
import { DestinationSharedFolderProfile } from 'src/app/models/destination/destination-shared-folder-profile';
import { DestinationSftpprofile } from 'src/app/models/destination/destination-sftp-profile';
import { DestinationFtpprofile } from 'src/app/models/destination/destination-ftp-profile';

@Injectable({
  providedIn: 'root',
})
export class MDDestinationService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
      this._http = http;
      this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getKeyVaultProfiles() {
      return this.http.get<Array<KeyVaultProfile>>(this.baseUrlLocal + 'bff/keyvaultprofiles');
    }

    public getPartnerDestinations(partnerId: number): Observable<Array<ConfDestination>> {
        return this._http.get<Array<ConfDestination>>(`${this.baseUrlLocal}confdestinations/${partnerId}`);
    }

    public addDestination(confDestination: ConfDestination) {
      return this.http.post(this.baseUrlLocal + 'confdestinations', confDestination);
    }

    public updateDestination(confDestination: ConfDestination) {
      return this.http.put(this.baseUrlLocal + 'confdestinations', confDestination);
    }

    public deleteDestination(confDestination: ConfDestination) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: confDestination
      };
      return this.http.delete(this.baseUrlLocal + 'confdestinations', options);
    }

    public SetupDestinationSbprofile(confDestination: ConfDestination) {
      confDestination.destinationSbprofile = new DestinationSbprofile();
      confDestination.destinationSbprofile.id = 0;
      confDestination.destinationSbprofile.authentication = 0;
      confDestination.destinationHttpprofile = null;
      confDestination.destinationMqprofile = null;
      confDestination.destinationSharedFolderProfile = null;
      confDestination.destinationSftpprofile = null;
    }

    public getEmptyDestinationForm(outboundPartnerId: number) : FormGroup {
      return new FormGroup({
          id: new FormControl(0, Validators.required),
          description: new FormControl('', Validators.required),
          type: new FormControl(0, Validators.required),
          outboundPartnerId: new FormControl(outboundPartnerId, Validators.required),
          protocol: new FormControl('', Validators.required)
      });
    }

    public getDestinationForm(outboundPartnerId: number, confDestination: ConfDestination) : FormGroup {
      let destinationForm: FormGroup = new FormGroup({
          id: new FormControl(confDestination.id, Validators.required),
          description: new FormControl(confDestination.description, Validators.required),
          type: new FormControl(confDestination.type, Validators.required),
          outboundPartnerId: new FormControl(outboundPartnerId, Validators.required),
          protocolDDL: new FormControl({value: confDestination.protocol, disabled: true}, Validators.required),
          protocol: new FormControl(confDestination.protocol, Validators.required),
          profileId: new FormControl(confDestination.profileId, Validators.required),
      });

      switch(+confDestination.protocol) { 
        case 1: { // SB
            const destinationSbprofileFormGroup: FormGroup = this.getDestinationSbprofileFormGroup(confDestination);
            destinationForm.addControl('destinationSbprofileFormGroup', destinationSbprofileFormGroup);
            break;
        }
        case 2: { // FILESHARE
          const destinationSharedFolderProfileFormGroup: FormGroup = this.getDestinationSharedFolderprofileFormGroup(confDestination);
          destinationForm.addControl('destinationSharedFolderProfileFormGroup', destinationSharedFolderProfileFormGroup);
            break; 
        } 
        case 3: { // FTP
          const destinationFtpprofileFormGroup: FormGroup = this.getDestinationFtpprofileFormGroup(confDestination);
          destinationForm.addControl('destinationFtpprofileFormGroup', destinationFtpprofileFormGroup);
            break;
        }
        case 7: { // MQ
          const destinationMqprofileFormGroup: FormGroup = this.getDestinationMqprofileFormGroup(confDestination);
          destinationForm.addControl('destinationMqprofileFormGroup', destinationMqprofileFormGroup);
            break;
        }
        case 8: { // SFTP
            const destinationSftpprofileFormGroup: FormGroup = this.getDestinationSftpprofileFormGroup(confDestination);
            destinationForm.addControl('destinationSftpprofileFormGroup', destinationSftpprofileFormGroup);
            break;
        }
        case 9: { // HTTP
          const destinationHttpprofileFormGroup: FormGroup = this.getDestinationHttpprofileFormGroup(confDestination);
          destinationForm.addControl('destinationHttpprofileFormGroup', destinationHttpprofileFormGroup);
          break; 
        } 
        default: { 
            break; 
        } 
      } 

      return destinationForm;
    }

    public getEmptyDestinationHttpprofileFormGroup(): FormGroup {
      const destinationHttpprofileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(0),
        useExistingKeyVaultValue: new FormControl(true),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(-1),
        password: new FormControl(null),
        port: new FormControl(null),
        userName: new FormControl(null),
        clientId: new FormControl(environment.transferClientId),
        tokenUrl: new FormControl(environment.transferTokenUrl),
        scope: new FormControl(null),
        systemName: new FormControl(null),
        host: new FormControl(null, Validators.required),
        path: new FormControl(environment.httpDestinationDefaultQueue),
      });

      return destinationHttpprofileFormGroup;
    }

    public getDestinationHttpprofileFormGroup(confDestination: ConfDestination): FormGroup {
      let useExistingKeyVaultValue: Boolean = false;
      if(confDestination.destinationHttpprofile.keyVaultProfile !== null) {
        useExistingKeyVaultValue = true;
      }

      const destinationHttpprofileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationHttpprofile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(confDestination.destinationHttpprofile.authentication),
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        useExistingDestination: new FormControl(false), // False as we want to be able to edit the values
        keyVaultProfileId: new FormControl(confDestination.destinationHttpprofile?.keyVaultProfileId),
        password: new FormControl(confDestination.destinationHttpprofile.password),
        port: new FormControl(confDestination.destinationHttpprofile.port),
        userName: new FormControl(confDestination.destinationHttpprofile.userName),
        clientId: new FormControl(confDestination.destinationHttpprofile.clientId),
        tokenUrl: new FormControl(confDestination.destinationHttpprofile.tokenUrl),
        scope: new FormControl(confDestination.destinationHttpprofile.scope),
        systemName: new FormControl(confDestination.destinationHttpprofile.systemName),
        host: new FormControl(confDestination.destinationHttpprofile.host, Validators.required),
        path: new FormControl(environment.httpDestinationDefaultQueue),
      });

      return destinationHttpprofileFormGroup;
    }

    public getEmptyDestinationSbprofileFormGroup(): FormGroup {
      const destinationSbprofileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(1), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(true),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(-1),
        namespace: new FormControl(null, Validators.required),
        queue: new FormControl(null, Validators.required),
        sharedAccessKeyName: new FormControl(null, Validators.required),
        systemName: new FormControl(null),
        useSession: new FormControl(null),
        connectionString: new FormControl(null), // This is used to pass the access key to server side to store in key vault
      });

      return destinationSbprofileFormGroup;
    }

    public getDestinationSbprofileFormGroup(confDestination: ConfDestination): FormGroup {
      let useExistingKeyVaultValue: Boolean = false;
      if(confDestination.destinationSbprofile.keyVaultProfile !== null) {
        useExistingKeyVaultValue = true;
      }

      const destinationSbprofileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationSbprofile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(confDestination.destinationSbprofile.authentication), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(confDestination.destinationSbprofile?.keyVaultProfileId),
        namespace: new FormControl(confDestination.destinationSbprofile.namespace, Validators.required),
        queue: new FormControl(confDestination.destinationSbprofile.queue, Validators.required),
        sharedAccessKeyName: new FormControl(confDestination.destinationSbprofile.sharedAccessKeyName, Validators.required),
        //systemName: new FormControl(confDestination.destinationSbprofile.),
        useSession: new FormControl(confDestination.destinationSbprofile.useSession),
        connectionString: new FormControl(confDestination.destinationSbprofile.connectionString), // This is used to pass the access key to server side to store in key vault
      });

      return destinationSbprofileFormGroup;
    }

    public getEmptyDestinationSftpprofileFormGroup(): FormGroup {
      const destinationSftpprofileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(true),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(-1),
        systemName: new FormControl(null),
        host: new FormControl(null, Validators.required),
        port: new FormControl(null),
        path: new FormControl(null),
        tempPath: new FormControl(null),
        userName: new FormControl(null),
        passPhrase: new FormControl(null),
        encoding: new FormControl(null),
        filename: new FormControl(null),
      });

      return destinationSftpprofileFormGroup;
    }

    public getDestinationSftpprofileFormGroup(confDestination: ConfDestination): FormGroup {
      let useExistingKeyVaultValue: Boolean = false;
      if(confDestination.destinationSftpprofile.keyVaultProfile !== null) {
        useExistingKeyVaultValue = true;
      }

      const destinationSftpprofileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationSftpprofile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(confDestination.destinationSftpprofile?.keyVaultProfileId),
        host: new FormControl(confDestination.destinationSftpprofile.host, Validators.required),
        port: new FormControl(confDestination.destinationSftpprofile.port),
        path: new FormControl(confDestination.destinationSftpprofile.path),
        tempPath: new FormControl(confDestination.destinationSftpprofile.tempPath),
        userName: new FormControl(confDestination.destinationSftpprofile.userName),
        passPhrase: new FormControl(confDestination.destinationSftpprofile.passPhrase),
        encoding: new FormControl(confDestination.destinationSftpprofile.encoding),
        filename: new FormControl(confDestination.destinationSftpprofile.filename),
      });

      return destinationSftpprofileFormGroup;
    }

    public getEmptyDestinationFtpprofileFormGroup(): FormGroup {
      const destinationFtpprofileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(true),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(-1),
        systemName: new FormControl(null),
        host: new FormControl(null, Validators.required),
        port: new FormControl(null),
        path: new FormControl(null),
        tempPath: new FormControl(null),
        userName: new FormControl(null),
        passPhrase: new FormControl(null),
        encoding: new FormControl(null),
        filename: new FormControl(null),
      });

      return destinationFtpprofileFormGroup;
    }

    public getDestinationFtpprofileFormGroup(confDestination: ConfDestination): FormGroup {
      let useExistingKeyVaultValue: Boolean = false;
      if(confDestination.destinationFtpprofile.keyVaultProfile !== null) {
        useExistingKeyVaultValue = true;
      }

      const destinationFtpprofileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationFtpprofile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        useExistingDestination: new FormControl(false),
        keyVaultProfileId: new FormControl(confDestination.destinationFtpprofile?.keyVaultProfileId),
        host: new FormControl(confDestination.destinationFtpprofile.host, Validators.required),
        port: new FormControl(confDestination.destinationFtpprofile.port),
        path: new FormControl(confDestination.destinationFtpprofile.path),
        tempPath: new FormControl(confDestination.destinationFtpprofile.tempPath),
        userName: new FormControl(confDestination.destinationFtpprofile.userName),
        passPhrase: new FormControl(confDestination.destinationFtpprofile.passPhrase),
        encoding: new FormControl(confDestination.destinationFtpprofile.encoding),
        filename: new FormControl(confDestination.destinationFtpprofile.filename),
      });

      return destinationFtpprofileFormGroup;
    }

    public getEmptyDestinationMqprofileFormGroup(): FormGroup {
      const destinationMqprofileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        useExistingDestination: new FormControl(false),
        host: new FormControl(null, Validators.required),
        channel: new FormControl(null, Validators.required),
        qm: new FormControl(null, Validators.required),
        queue: new FormControl(null, Validators.required),
        port: new FormControl(null, Validators.required),
      });

      return destinationMqprofileFormGroup;
    }

    public getDestinationMqprofileFormGroup(confDestination: ConfDestination): FormGroup {
      const destinationMqprofileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationMqprofile.id, Validators.required), // empty string to match DDL for not selected value
        useExistingDestination: new FormControl(false),
        host: new FormControl(confDestination.destinationMqprofile.host, Validators.required),
        channel: new FormControl(confDestination.destinationMqprofile.channel, Validators.required),
        qm: new FormControl(confDestination.destinationMqprofile.qm, Validators.required),
        queue: new FormControl(confDestination.destinationMqprofile.queue, Validators.required),
        port: new FormControl(confDestination.destinationMqprofile.port, Validators.required),
      });

      return destinationMqprofileFormGroup;
    }

    public getEmptyDestinationSharedFolderprofileFormGroup(): FormGroup {
      const destinationSharedFolderProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        useExistingDestination: new FormControl(false),
        server: new FormControl(null, Validators.required),
        path: new FormControl(null, Validators.required),
        filter: new FormControl(null),
        encoding: new FormControl(null),
        filename: new FormControl(null),
      });

      return destinationSharedFolderProfileFormGroup;
    }

    public getDestinationSharedFolderprofileFormGroup(confDestination: ConfDestination): FormGroup {
      const destinationSharedFolderProfileFormGroup = new FormGroup({
        id: new FormControl(confDestination.destinationSharedFolderProfile.id, Validators.required), // empty string to match DDL for not selected value
        useExistingDestination: new FormControl(false),
        server: new FormControl(confDestination.destinationSharedFolderProfile.server, Validators.required),
        path: new FormControl(confDestination.destinationSharedFolderProfile.path, Validators.required),
        filter: new FormControl(confDestination.destinationSharedFolderProfile.filter),
        encoding: new FormControl(confDestination.destinationSharedFolderProfile.encoding),
        filename: new FormControl(confDestination.destinationSharedFolderProfile.filename),
      });

      return destinationSharedFolderProfileFormGroup;
    }

    public setupDestinationFormGroup(destinationForm: FormGroup) {
      let destinationProtocol: string = destinationForm.value.protocol;

        switch(destinationProtocol) { 
            case '1': { // SB
                const destinationSbprofileFormGroup: FormGroup = this.getEmptyDestinationSbprofileFormGroup();
                destinationForm.addControl('destinationSbprofileFormGroup', destinationSbprofileFormGroup);

                if (destinationForm.contains('destinationFtpprofileFormGroup')) {
                    destinationForm.removeControl('destinationFtpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSftpprofileFormGroup')) {
                    destinationForm.removeControl('destinationSftpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSharedFolderProfileFormGroup')) {
                    destinationForm.removeControl('destinationSharedFolderProfileFormGroup');
                }
                if (destinationForm.contains('destinationHttpprofileFormGroup')) {
                    destinationForm.removeControl('destinationHttpprofileFormGroup');
                }
                if (destinationForm.contains('destinationMqprofileFormGroup')) {
                    destinationForm.removeControl('destinationMqprofileFormGroup');
                }
                break; 
            }
            case '2': { // FILESHARE
                const destinationSharedFolderProfileFormGroup: FormGroup = this.getEmptyDestinationSharedFolderprofileFormGroup();
                destinationForm.addControl('destinationSharedFolderProfileFormGroup', destinationSharedFolderProfileFormGroup);
                
                if (destinationForm.contains('destinationFtpprofileFormGroup')) {
                    destinationForm.removeControl('destinationFtpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSftpprofileFormGroup')) {
                    destinationForm.removeControl('destinationSftpprofileFormGroup');
                }                
                if (destinationForm.contains('destinationSbprofileFormGroup')) {
                    destinationForm.removeControl('destinationSbprofileFormGroup');
                }
                if (destinationForm.contains('destinationHttpprofileFormGroup')) {
                    destinationForm.removeControl('destinationHttpprofileFormGroup');
                }
                if (destinationForm.contains('destinationMqprofileFormGroup')) {
                    destinationForm.removeControl('destinationMqprofileFormGroup');
                }                
                break; 
            } 
            case '7': { // MQ
                const destinationMqprofileFormGroup: FormGroup = this.getEmptyDestinationMqprofileFormGroup();
                destinationForm.addControl('destinationMqprofileFormGroup', destinationMqprofileFormGroup);

                if (destinationForm.contains('destinationFtpprofileFormGroup')) {
                    destinationForm.removeControl('destinationFtpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSftpprofileFormGroup')) {
                    destinationForm.removeControl('destinationSftpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSharedFolderProfileFormGroup')) {
                    destinationForm.removeControl('destinationSharedFolderProfileFormGroup');
                }
                if (destinationForm.contains('destinationSbprofileFormGroup')) {
                    destinationForm.removeControl('destinationSbprofileFormGroup');
                }
                if (destinationForm.contains('destinationHttpprofileFormGroup')) {
                    destinationForm.removeControl('destinationHttpprofileFormGroup');
                }
                break; 
            } 
            
            case '8': { // SFTP
                const destinationSftpprofileFormGroup: FormGroup = this.getEmptyDestinationSftpprofileFormGroup();
                destinationForm.addControl('destinationSftpprofileFormGroup', destinationSftpprofileFormGroup);

                if (destinationForm.contains('destinationFtpprofileFormGroup')) {
                    destinationForm.removeControl('destinationFtpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSharedFolderProfileFormGroup')) {
                    destinationForm.removeControl('destinationSharedFolderProfileFormGroup');
                }
                if (destinationForm.contains('destinationSbprofileFormGroup')) {
                    destinationForm.removeControl('destinationSbprofileFormGroup');
                }
                if (destinationForm.contains('destinationHttpprofileFormGroup')) {
                    destinationForm.removeControl('destinationHttpprofileFormGroup');
                }
                if (destinationForm.contains('destinationMqprofileFormGroup')) {
                    destinationForm.removeControl('destinationMqprofileFormGroup');
                }                
                break; 
            }
            case '3': { // FTP
                const destinationFtpprofileFormGroup: FormGroup = this.getEmptyDestinationFtpprofileFormGroup();
                destinationForm.addControl('destinationFtpprofileFormGroup', destinationFtpprofileFormGroup);

                if (destinationForm.contains('destinationSftpprofileFormGroup')) {
                    destinationForm.removeControl('destinationSftpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSharedFolderProfileFormGroup')) {
                    destinationForm.removeControl('destinationSharedFolderProfileFormGroup');
                }
                if (destinationForm.contains('destinationSbprofileFormGroup')) {
                    destinationForm.removeControl('destinationSbprofileFormGroup');
                }
                if (destinationForm.contains('destinationHttpprofileFormGroup')) {
                    destinationForm.removeControl('destinationHttpprofileFormGroup');
                }
                if (destinationForm.contains('destinationMqprofileFormGroup')) {
                    destinationForm.removeControl('destinationMqprofileFormGroup');
                }                
                break; 
            }
            case '9': { // HTTP
                const destinationHttpprofileFormGroup: FormGroup = this.getEmptyDestinationHttpprofileFormGroup();
                destinationForm.addControl('destinationHttpprofileFormGroup', destinationHttpprofileFormGroup);

                if (destinationForm.contains('destinationFtpprofileFormGroup')) {
                    destinationForm.removeControl('destinationFtpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSftpprofileFormGroup')) {
                    destinationForm.removeControl('destinationSftpprofileFormGroup');
                }
                if (destinationForm.contains('destinationSharedFolderProfileFormGroup')) {
                    destinationForm.removeControl('destinationSharedFolderProfileFormGroup');
                }
                if (destinationForm.contains('destinationSbprofileFormGroup')) {
                    destinationForm.removeControl('destinationSbprofileFormGroup');
                }
                if (destinationForm.contains('destinationMqprofileFormGroup')) {
                    destinationForm.removeControl('destinationMqprofileFormGroup');
                }

                break; 
            } 
            
            default: { 
                break; 
            } 
        } 
    }

    public getConfDestinationRequestModel(destinationForm: FormGroup): ConfDestination {
      let confDestination: ConfDestination = new ConfDestination();
      confDestination.id = +destinationForm.value.id;
      confDestination.protocol = +destinationForm.value.protocol;        
      confDestination.type = destinationForm.value.type; // Endpoint Type, Primary = 0, Secondary = 1
      confDestination.outboundPartnerId = +destinationForm.value.outboundPartnerId;
      confDestination.profileId = +destinationForm.value.profileId;
      confDestination.description = destinationForm.value.description;
      confDestination.destinationFtpprofile = null;
      confDestination.destinationSftpprofile = null;
      confDestination.destinationSbprofile = null;
      confDestination.destinationMqprofile = null;
      confDestination.destinationSharedFolderProfile = null;
      confDestination.destinationHttpprofile = null;

      return confDestination;
    }

    public getDestinationHttpprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationHttpprofile {
      let destinationHttpprofile: DestinationHttpprofile = new DestinationHttpprofile();
      destinationHttpprofile.id = +destinationForm.value.destinationHttpprofileFormGroup.id;
      destinationHttpprofile.name = confDestination.description;
      destinationHttpprofile.authentication = +destinationForm.value.destinationHttpprofileFormGroup.authentication;
      destinationHttpprofile.host = destinationForm.value.destinationHttpprofileFormGroup.host;
      destinationHttpprofile.port = destinationForm.value.destinationHttpprofileFormGroup.port;
      destinationHttpprofile.path = destinationForm.value.destinationHttpprofileFormGroup.path;
      destinationHttpprofile.password = destinationForm.value.destinationHttpprofileFormGroup.password;
      destinationHttpprofile.userName = destinationForm.value.destinationHttpprofileFormGroup.userName;

      destinationHttpprofile.clientId = destinationForm.value.destinationHttpprofileFormGroup.clientId;
      destinationHttpprofile.tokenUrl = destinationForm.value.destinationHttpprofileFormGroup.tokenUrl;
      destinationHttpprofile.scope = destinationForm.value.destinationHttpprofileFormGroup.scope;

      if(+destinationForm.value.destinationHttpprofileFormGroup.keyVaultProfileId !== -1) {
        destinationHttpprofile.keyVaultProfileId = destinationForm.value.destinationHttpprofileFormGroup.keyVaultProfileId;
      } else {
        destinationHttpprofile.keyVaultProfileId = 0;
      }

      if(+destinationHttpprofile.authentication !== 0) {
          destinationHttpprofile.keyVaultProfile = new KeyVaultProfile();
          let systemName: string = destinationForm.value.destinationHttpprofileFormGroup.systemName;

          switch(+destinationHttpprofile.authentication) {             
              case 1: { 
                  destinationHttpprofile.keyVaultProfile.secretName = `al-goh-http-basicauth-out-${systemName}`
                  break;
              } 
              case 2: { 
                destinationHttpprofile.keyVaultProfile.secretName = `al-goh-http-oauth2-out-${systemName}`
                break;
              } 
              case 4: { 
                  destinationHttpprofile.keyVaultProfile.secretName = `al-goh-http-shared-bearer-token-out-${systemName}`
                  break;
              }
              case 5: { 
                  destinationHttpprofile.keyVaultProfile.secretName = `al-goh-http-static-authentication-token-out-${systemName}`
                  break;
              } 
              default: { 
                  //statements; 
                  break;
              }
          }

          destinationHttpprofile.keyVaultProfile.name = `${systemName} HTTP Outbound Secret`
      } else {
          destinationHttpprofile.keyVaultProfile = null;
      }
      
      return destinationHttpprofile;
    }

    public getDestinationSbprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationSbprofile {
      let destinationSbprofile: DestinationSbprofile = new DestinationSbprofile();
      
      destinationSbprofile.id = +destinationForm.value.destinationSbprofileFormGroup.id; // zero for new
      destinationSbprofile.name = confDestination.description;
      destinationSbprofile.authentication = +destinationForm.value.destinationSbprofileFormGroup.authentication;
      
      if(destinationForm.value.destinationSbprofileFormGroup.namespace.includes(".servicebus.windows.net") === true) {
        destinationSbprofile.namespace = destinationForm.value.destinationSbprofileFormGroup.namespace;
      } else {
        destinationSbprofile.namespace = `${destinationForm.value.destinationSbprofileFormGroup.namespace}.servicebus.windows.net`;
      }

      destinationSbprofile.queue = destinationForm.value.destinationSbprofileFormGroup.queue;
      destinationSbprofile.useSession = destinationForm.value.destinationSbprofileFormGroup.useSession;
      destinationSbprofile.sharedAccessKeyName = destinationForm.value.destinationSbprofileFormGroup.sharedAccessKeyName;
      destinationSbprofile.connectionString = destinationForm.value.destinationSbprofileFormGroup.connectionString;

      // TODO Handle new secret
      if(+destinationForm.value.destinationSbprofileFormGroup.keyVaultProfileId !== -1) {
        destinationSbprofile.keyVaultProfileId = destinationForm.value.destinationSbprofileFormGroup.keyVaultProfileId;
      } else {
        destinationSbprofile.keyVaultProfileId = 0;
      }

      if(+destinationSbprofile.authentication !== 0) {
        destinationSbprofile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = destinationForm.value.destinationSbprofileFormGroup.systemName;
        destinationSbprofile.keyVaultProfile.secretName = `al-goh-servicebus-auth-out-${destinationForm.value.destinationSbprofileFormGroup.systemName}-key`;
        destinationSbprofile.keyVaultProfile.name = `${systemName} Service Bus Outbound Secret`;
      } else {
        destinationSbprofile.keyVaultProfile = null;
      }
      
      return destinationSbprofile;
    }

    public getDestinationMqprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationMqprofile {
      let destinationMqprofile: DestinationMqprofile = new DestinationMqprofile();
      
      destinationMqprofile.id = 0; // zero for new
      destinationMqprofile.name = confDestination.description;
      destinationMqprofile.host = destinationForm.value.destinationMqprofileFormGroup.host;
      destinationMqprofile.channel = destinationForm.value.destinationMqprofileFormGroup.channel;
      destinationMqprofile.qm = destinationForm.value.destinationMqprofileFormGroup.qm;
      destinationMqprofile.queue = destinationForm.value.destinationMqprofileFormGroup.queue;
      destinationMqprofile.port = destinationForm.value.destinationMqprofileFormGroup.port;

      return destinationMqprofile;
    }

    public getDestinationSharedFolderprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationSharedFolderProfile {
      let destinationSharedFolderProfile: DestinationSharedFolderProfile = new DestinationSharedFolderProfile();
      
      destinationSharedFolderProfile.id = 0; // zero for new
      destinationSharedFolderProfile.name = confDestination.description;
      destinationSharedFolderProfile.server = destinationForm.value.destinationSharedFolderProfileFormGroup.server;
      destinationSharedFolderProfile.path = destinationForm.value.destinationSharedFolderProfileFormGroup.path;
      destinationSharedFolderProfile.filter = destinationForm.value.destinationSharedFolderProfileFormGroup.filter;
      destinationSharedFolderProfile.encoding = destinationForm.value.destinationSharedFolderProfileFormGroup.encoding;
      destinationSharedFolderProfile.filename = destinationForm.value.destinationSharedFolderProfileFormGroup.filename;

      return destinationSharedFolderProfile;
    }

    public getDestinationSftpprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationSftpprofile {
      let destinationSftpprofile: DestinationSftpprofile = new DestinationSftpprofile();
      
      destinationSftpprofile.id = 0; // zero for new
      destinationSftpprofile.name = confDestination.description;
      destinationSftpprofile.authenticationTypeId = +destinationForm.value.destinationSftpprofileFormGroup.authentication;

      destinationSftpprofile.host = destinationForm.value.destinationSftpprofileFormGroup.host;
      destinationSftpprofile.port = destinationForm.value.destinationSftpprofileFormGroup.port;
      destinationSftpprofile.path = destinationForm.value.destinationSftpprofileFormGroup.path;
      destinationSftpprofile.tempPath = destinationForm.value.destinationSftpprofileFormGroup.tempPath;
      destinationSftpprofile.userName = destinationForm.value.destinationSftpprofileFormGroup.userName;
      destinationSftpprofile.passPhrase = destinationForm.value.destinationSftpprofileFormGroup.passPhrase;
      destinationSftpprofile.encoding = destinationForm.value.destinationSftpprofileFormGroup.encoding;
      destinationSftpprofile.filename = destinationForm.value.destinationSftpprofileFormGroup.filename;

      // TODO Handle new secret
      if(+destinationForm.value.destinationSftpprofileFormGroup.keyVaultProfileId !== -1) {
        destinationSftpprofile.keyVaultProfileId = destinationForm.value.destinationSftpprofileFormGroup.keyVaultProfileId;
      } else {
        destinationSftpprofile.keyVaultProfileId = 0;
      }

      if(+destinationSftpprofile.authenticationTypeId !== 0) {
        destinationSftpprofile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = destinationForm.value.destinationSftpprofileFormGroup.systemName;
        destinationSftpprofile.keyVaultProfile.secretName = `al-goh-sftp-auth-out-${destinationForm.value.destinationSftpprofileFormGroup.systemName}-password`
        destinationSftpprofile.keyVaultProfile.name = `${systemName} SFTP Outbound Secret`
      } else {
        destinationSftpprofile.keyVaultProfile = null;
      }

      return destinationSftpprofile;
    }

    public getDestinationFtpprofileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination): DestinationFtpprofile {
      let destinationFtpprofile: DestinationFtpprofile = new DestinationFtpprofile();
      
      destinationFtpprofile.id = 0; // zero for new
      destinationFtpprofile.name = confDestination.description;
      destinationFtpprofile.authenticationTypeId = +destinationForm.value.destinationFtpprofileFormGroup.authentication;

      destinationFtpprofile.host = destinationForm.value.destinationFtpprofileFormGroup.host;
      destinationFtpprofile.port = destinationForm.value.destinationFtpprofileFormGroup.port;
      destinationFtpprofile.path = destinationForm.value.destinationFtpprofileFormGroup.path;
      destinationFtpprofile.tempPath = destinationForm.value.destinationFtpprofileFormGroup.tempPath;
      destinationFtpprofile.userName = destinationForm.value.destinationFtpprofileFormGroup.userName;
      destinationFtpprofile.passPhrase = destinationForm.value.destinationFtpprofileFormGroup.passPhrase;
      destinationFtpprofile.encoding = destinationForm.value.destinationFtpprofileFormGroup.encoding;
      destinationFtpprofile.filename = destinationForm.value.destinationFtpprofileFormGroup.filename;

      // TODO Handle new secret
      if(+destinationForm.value.destinationFtpprofileFormGroup.keyVaultProfileId !== -1) {
        destinationFtpprofile.keyVaultProfileId = destinationForm.value.destinationFtpprofileFormGroup.keyVaultProfileId;
      } else {
        destinationFtpprofile.keyVaultProfileId = 0;
      }

      if(+destinationFtpprofile.authenticationTypeId !== 0) {
        destinationFtpprofile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = destinationForm.value.destinationFtpprofileFormGroup.systemName;
        destinationFtpprofile.keyVaultProfile.secretName = `al-goh-ftp-auth-out-${destinationForm.value.destinationFtpprofileFormGroup.systemName}-password`
        destinationFtpprofile.keyVaultProfile.name = `${systemName} FTP Outbound Secret`
      } else {
        destinationFtpprofile.keyVaultProfile = null;
      }

      return destinationFtpprofile;
    }

    public setupProfileRequestModel(destinationForm: FormGroup, confDestination: ConfDestination, isEdit: boolean) {
      switch(+confDestination.protocol) { 
        case 1: { // SB
            if(isEdit || (destinationForm.value.destinationSbprofileFormGroup.id === '' || destinationForm.value.destinationSbprofileFormGroup.id === 0)) {
                confDestination.destinationSbprofile = this.getDestinationSbprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationSbprofileFormGroup.id;
            }
            break;
        }
        case 2: { // FILESHARE
            if(isEdit || (destinationForm.value.destinationSharedFolderProfileFormGroup.id === '' || destinationForm.value.destinationSharedFolderProfileFormGroup.id === 0)) {
                confDestination.destinationSharedFolderProfile = this.getDestinationSharedFolderprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationSharedFolderProfileFormGroup.id;
            }
            break; 
        } 
        case 3: { // SFTP
            if(isEdit || (destinationForm.value.destinationFtpprofileFormGroup.id === '' || destinationForm.value.destinationFtpprofileFormGroup.id === 0)) {
                confDestination.destinationFtpprofile = this.getDestinationFtpprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationFtpprofileFormGroup.id;
            }
            break;
        }
        case 7: { // MQ
            if(isEdit || (destinationForm.value.destinationMqprofileFormGroup.id === '' || destinationForm.value.destinationMqprofileFormGroup.id === 0)) {
                confDestination.destinationMqprofile = this.getDestinationMqprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationMqprofileFormGroup.id;
            }
            break;
        }
        case 8: { // SFTP
            if(isEdit || (destinationForm.value.destinationSftpprofileFormGroup.id === '' || destinationForm.value.destinationSftpprofileFormGroup.id === 0)) {
                confDestination.destinationSftpprofile = this.getDestinationSftpprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationSftpprofileFormGroup.id;
            }
            break;
        }
        case 9: { // HTTP
            if(isEdit || (destinationForm.value.destinationHttpprofileFormGroup.id === '' || destinationForm.value.destinationHttpprofileFormGroup.id === 0)) {
                confDestination.destinationHttpprofile = this.getDestinationHttpprofileRequestModel(destinationForm, confDestination);                    
            } else {
                confDestination.profileId = +destinationForm.value.destinationHttpprofileFormGroup.id;
            }
            break; 
        } 
        default: { 
            break; 
        } 
      } 
    }
}
