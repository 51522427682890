<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>    
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Destinations</span></legend>

        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>A message can be sent to one or more destinations. Destinations protocals can be HTTP endpoint, Service Bus, MQ or File Share.</small></span>
        </div>
        
          <hr />
        
        <!-- Partner configured destinations -->
        <div>
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Existing destinatons</span></legend>
                <!-- <div *ngIf="destinations != undefined && destinations.length === 0">
                    <p>No destinations configured</p>
                </div> -->
                <table class='table table-striped table-bordered' aria-labelledby="tableLabel" >
                    <thead>
                        <tr>
                          <th scope="col" colSpan="7">
                            <div>
                              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="openAddDestinationModal()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            </div>
                          </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>                        
                            <th>Description</th>
                            <th>Protocol</th>
                            <th>Endpoint type</th>
                            <th>Physical destination</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="destinations != undefined && destinations.length === 0">
                        <tr>
                            <td scope="col" colSpan="7">No destinations configured</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="destinations != undefined && destinations.length > 0">
                        <tr *ngFor="let d of destinations">
                            <td>{{d.description}}</td>
                            <td>{{ utilService.getProtocolName(d.protocol) }}</td>
                            <td style="max-width: 60px;">{{ utilService.getTypeText(d.type) }}</td>
                            <!-- 
                            password: string;
                            systemName: string; -->
                            <td>
                                <div *ngIf="d.destinationHttpprofile !== null">
                                    <row-display [label]="'Name:'" [value]="d.destinationHttpprofile.name"></row-display>
                                    <row-display [label]="'Host:'" [value]="d.destinationHttpprofile.host"></row-display>
                                    <row-display [label]="'Port:'" [value]="d.destinationHttpprofile.port"></row-display>
                                    <row-display [label]="'Path:'" [value]="d.destinationHttpprofile.path"></row-display>
                                    <row-display [label]="'Authentication:'" [value]="utilService.getAuthenticationName(d.destinationHttpprofile.authentication)"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.authentication === 1" [label]="'Username:'" [value]="d.destinationHttpprofile.userName"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.authentication === 2" [label]="'Client Id:'" [value]="d.destinationHttpprofile.clientId"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.authentication === 2" [label]="'Token URL:'" [value]="d.destinationHttpprofile.tokenUrl"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.authentication === 2" [label]="'Scope:'" [value]="d.destinationHttpprofile.scope"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.destinationHttpprofile.keyVaultProfile.keyVaultName"></row-display>
                                    <row-display *ngIf="d.destinationHttpprofile.keyVaultProfileId != undefined" [label]="'Key vault secret:'" [value]="d.destinationHttpprofile.keyVaultProfile.secretName"></row-display>
                                </div>
                                <div *ngIf="d.destinationMqprofile !== null">
                                    <!-- 
                                    password: string;
                                    keyVaultProfile: KeyVaultProfile; -->

                                    <row-display [label]="'Name:'" [value]="d.destinationMqprofile.name"></row-display>
                                    <row-display [label]="'Host:'" [value]="d.destinationMqprofile.host"></row-display>
                                    <row-display [label]="'Port:'" [value]="d.destinationMqprofile.port"></row-display>
                                    <row-display [label]="'Channel:'" [value]="d.destinationMqprofile.channel"></row-display>
                                    <row-display [label]="'Queue manager:'" [value]="d.destinationMqprofile.qm"></row-display>
                                    <row-display [label]="'Queue:'" [value]="d.destinationMqprofile.queue"></row-display>
                                    <row-display [label]="'Username:'" [value]="d.destinationMqprofile.userName"></row-display>
                              
                                </div>
                                <div *ngIf="d.destinationSbprofile !== null">
                                    <!-- 
                                    authentication: number;
                                    keyVaultProfile: KeyVaultProfile;                                
                                    -->
                                    <row-display [label]="'Name:'" [value]="d.destinationSbprofile.name"></row-display>
                                    <row-display [label]="'Namespace:'" [value]="d.destinationSbprofile.namespace"></row-display>
                                    <row-display [label]="'Queue:'" [value]="d.destinationSbprofile.queue"></row-display>
                                    <row-display [label]="'Shared access key:'" [value]="d.destinationSbprofile.sharedAccessKeyName"></row-display>
                                    <row-display [label]="'Use session:'" [value]="d.destinationSbprofile.useSession === false ? 'NO' : 'YES'"></row-display>
                                    <row-display *ngIf="d.destinationSbprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.destinationSbprofile.keyVaultProfile.keyVaultName"></row-display>
                                    <row-display *ngIf="d.destinationSbprofile.keyVaultProfileId != undefined" [label]="'Key vault secret:'" [value]="d.destinationSbprofile.keyVaultProfile.secretName"></row-display>
                                </div>
                                <div *ngIf="d.destinationSharedFolderProfile !== null">
                                    <row-display [label]="'Name:'" [value]="d.destinationSharedFolderProfile.name"></row-display>
                                    <row-display [label]="'Server:'" [value]="d.destinationSharedFolderProfile.server"></row-display>
                                    <row-display [label]="'Path:'" [value]="d.destinationSharedFolderProfile.path"></row-display>
                                    <row-display [label]="'Filter:'" [value]="d.destinationSharedFolderProfile.filter"></row-display>
                                    <row-display [label]="'Encoding:'" [value]="d.destinationSharedFolderProfile.encoding"></row-display>
                                    <row-display [label]="'Filename:'" [value]="d.destinationSharedFolderProfile.filename"></row-display>
                                </div>
                                <div *ngIf="d.destinationSftpprofile !== null">
                                    <row-display [label]="'Name:'" [value]="d.destinationSftpprofile.name"></row-display>
                                    <row-display [label]="'Host:'" [value]="d.destinationSftpprofile.host"></row-display>
                                    <row-display [label]="'Port:'" [value]="d.destinationSftpprofile.port"></row-display>
                                    <row-display [label]="'Path:'" [value]="d.destinationSftpprofile.path"></row-display>
                                    <row-display [label]="'Temporary path:'" [value]="d.destinationSftpprofile.tempPath"></row-display>
                                    <row-display [label]="'Encoding:'" [value]="d.destinationSftpprofile.encoding"></row-display>
                                    <row-display [label]="'Filename:'" [value]="d.destinationSftpprofile.filename"></row-display>
                                    <row-display [label]="'Username:'" [value]="d.destinationSftpprofile.userName"></row-display>
                                    <row-display *ngIf="d.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.destinationSftpprofile.keyVaultProfile.keyVaultName"></row-display>
                                    <row-display *ngIf="d.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Key vault secret:'" [value]="d.destinationSftpprofile.keyVaultProfile.secretName"></row-display>
                                </div>
                                <div *ngIf="d.destinationFtpprofile !== null">
                                    <row-display [label]="'Name:'" [value]="d.destinationFtpprofile.name"></row-display>
                                    <row-display [label]="'Host:'" [value]="d.destinationFtpprofile.host"></row-display>
                                    <row-display [label]="'Port:'" [value]="d.destinationFtpprofile.port"></row-display>
                                    <row-display [label]="'Path:'" [value]="d.destinationFtpprofile.path"></row-display>
                                    <row-display [label]="'Temporary path:'" [value]="d.destinationFtpprofile.tempPath"></row-display>
                                    <row-display [label]="'Encoding:'" [value]="d.destinationFtpprofile.encoding"></row-display>
                                    <row-display [label]="'Filename:'" [value]="d.destinationFtpprofile.filename"></row-display>
                                    <row-display [label]="'Username:'" [value]="d.destinationFtpprofile.userName"></row-display>
                                    <row-display *ngIf="d.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.destinationFtpprofile.keyVaultProfile.keyVaultName"></row-display>
                                    <row-display *ngIf="d.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Key vault secret:'" [value]="d.destinationFtpprofile.keyVaultProfile.secretName"></row-display>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-outline-secondary btn-sm" (click)="editDestination(d)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button class="btn btn-outline-secondary btn-sm" (click)="removeDestinaton(d)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>                
                </table>
            </fieldset>
            <br />
        </div>

        <!-- <div *ngIf="destinations">
            <pre style="margin-top:10px; border: 1px solid red; width: fit-content">
            {{ destinations | json }}
            </pre>
              </div>   -->

    </fieldset>
</div>