/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

 import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
 import { environment } from '../environments/environment';

 const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

 /**
  * Configuration object to be passed to MSAL instance on creation.
  * For a full list of MSAL.js configuration parameters, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
  */

  let value = 100;
  console.log(`The size is ${ value }`);

 export const msalConfig: Configuration = {
     auth: {
         clientId: `${environment.adminToolClientId}`, // This is the ONLY mandatory field that you need to supply.
         authority: `https://login.microsoftonline.com/${environment.adminToolTenentId}`, // Defaults to "https://login.microsoftonline.com/common"
         redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
         postLogoutRedirectUri: '/', // Points to window.location.origin by default.
         clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
         storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
     },
     system: {
         /**
          * Below you can configure MSAL.js logs. For more information, visit:
          * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
          */
         loggerOptions: {
             loggerCallback(logLevel: LogLevel, message: string) {
                 console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

 /**
  * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
  */
 export const protectedResources = {
    ConfigurationsEndpoint: {
        endpoint: `${environment.apiBaseUrl}configurations`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    wizardEndpoint: {
        endpoint: `${environment.apiBaseUrl}wizard`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    bffEndpoint: {
        endpoint: `${environment.apiBaseUrl}bff`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    customerConfigurations: {
        endpoint: `${environment.apiBaseUrl}CustomerConfigurations`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    partners: {
        endpoint: `${environment.apiBaseUrl}partners`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    parsersClasses: {
        endpoint: `${environment.apiBaseUrl}parserClasses`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundParser: {
        endpoint: `${environment.apiBaseUrl}inboundParser`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundMessageTypes: {
        endpoint: `${environment.apiBaseUrl}inboundmessagetypes`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundMessageTypesSearch: {
        endpoint: `${environment.apiBaseUrl}inboundmessagetypes/search`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundMessageTypes: {
        endpoint: `${environment.apiBaseUrl}outboundmessagetypes`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundMessageTypesSearch: {
        endpoint: `${environment.apiBaseUrl}outboundmessagetypes/search`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    testEdi: {
        endpoint: `${environment.apiBaseUrl}testedi`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    resolverTest: {
        endpoint: `${environment.apiBaseUrl}resolver`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    scheduler: {
        endpoint: `${environment.apiBaseUrl}scheduler`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    resolverClasses: {
        endpoint: `${environment.apiBaseUrl}resolverclasses`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    edifactdecode: {
        endpoint: `${environment.apiBaseUrl}edifactdecode`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    edifactencode: {
        endpoint: `${environment.apiBaseUrl}edifactencode`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    x12decode: {
        endpoint: `${environment.apiBaseUrl}x12decode`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    x12encode: {
        endpoint: `${environment.apiBaseUrl}x12encode`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    agreements: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/agreements`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    certificates: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/certificates`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    schemas: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/schemas`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    maps: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/maps`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    edipartners: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/partners`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    edipartner: {
        endpoint: `${environment.apiBaseUrl}integrationaccount/partner`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    keyvaultsecret: {
        endpoint: `${environment.apiBaseUrl}keyvault/secret`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundmappings: {
        endpoint: `${environment.apiBaseUrl}inboundmappings`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundmappingsmappingresponse: {
        endpoint: `${environment.apiBaseUrl}inboundmappings/mappingresponse`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundmappingdocumentssearch: {
        endpoint: `${environment.apiBaseUrl}inboundmappingsxslt/search`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    inboundmappingdocuments: {
        endpoint: `${environment.apiBaseUrl}inboundmappingsxslt`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundmappings: {
        endpoint: `${environment.apiBaseUrl}outboundmappings`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundmappingspartnerDocuments: {
      endpoint: `${environment.apiBaseUrl}outboundmappings`,
      scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundmappingsmappingresponse: {
        endpoint: `${environment.apiBaseUrl}outboundmappings/mappingresponse`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundmappingdocumentssearch: {
        endpoint: `${environment.apiBaseUrl}outboundmappingsxslt/search`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    outboundmappingdocuments: {
        endpoint: `${environment.apiBaseUrl}outboundmappingsxslt`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    tracking: {
        endpoint: `${environment.apiBaseUrl}tracking`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    trackingv2: {
        endpoint: `${environment.apiTrackingBaseUrl}tracking`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    destinations: {
        endpoint: `${environment.apiBaseUrl}destinations`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    confdestinations: {
        endpoint: `${environment.apiBaseUrl}confdestinations`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    edipreformatter: {
        endpoint: `${environment.apiBaseUrl}edipreformatter`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    blob: {
        endpoint: `${environment.apiBaseUrl}blob`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    orderlinesperday: {
        endpoint: `${environment.apiBaseUrl}statistics/orderlinesperday`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    orderspersender: {
        endpoint: `${environment.apiBaseUrl}statistics/orderspersender`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    messagespersender: {
        endpoint: `${environment.apiBaseUrl}statistics/messagespersender`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    messagesperday: {
        endpoint: `${environment.apiBaseUrl}statistics/messagesperday`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    prestepclasses: {
        endpoint: `${environment.apiBaseUrl}prestepclasses`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    prestepclassesall: {
        endpoint: `${environment.apiBaseUrl}prestepclasses/all`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    prestep: {
        endpoint: `${environment.apiBaseUrl}prestep`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    simpleconfigurations: {
        endpoint: `${environment.apiBaseUrl}simpleconfigurations`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    servicemode: {
        endpoint: `${environment.apiBaseUrl}servicemode`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    as2confin: {
        endpoint: `${environment.apiBaseUrl}as2confin`,
        scopes: [`api://${environment.adminToolClientId}/access_via_approle_assignments`]
    },
    

 }

 /**
  * Scopes you add here will be prompted for user consent during sign-in.
  * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
  * For more information about OIDC scopes, visit:
  * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
  */
 export const loginRequest = {
     scopes: [...protectedResources.customerConfigurations.scopes]
 };

 export const roles = {
    AdminToolReader: "GOH.AdminTool.Reader",
    AdminToolOnboarder: "GOH.AdminTool.Onboarder",
    AdminToolAdmin: "GOH.AdminTool.Admin",
    AdminToolSuperUser: "GOH.AdminTool.SuperUser"
 }
